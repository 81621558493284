// https://www.freakyjolly.com/google-signin-login-button-in-react-js-example-using-react-google_login-package/

import axios from "axios";
import React, { useEffect, useCallback } from "react";

import {
  AppBar,
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  Toolbar,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { tokenExpired } from "./token.js";
import urlConfig from "./urlConfig.js";

import "./App.css";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const env = urlConfig.getEnv(window.location.origin);
  console.log(env);

  const serverURL = urlConfig.serverURL(env);
  const clientURL = urlConfig.clientURL(env);

  const createGoogleAuthLink = async () => {
    try {
      const url = serverURL + "/api/google/auth";
      const response = await axios.get(url);
      window.location.href = response.data.url;
    } catch (err) {
      throw new Error("App.js - could not get auth link", err.message);
    }
  };

  const handleTokenFromQueryParams = useCallback(() => {
    const query = new URLSearchParams(window.location.search);
    for (var key of query.keys()) {
      if (key === "code") {
        const url = serverURL + "/api/google/redirect" + window.location.search;
        axios.get(url).then((response) => {
          const accessToken = response.data.access_token;
          const refreshToken = response.data.refresh_token;
          const expirationDate = response.data.expiry_date;
          if (accessToken && refreshToken) {
            storeTokenData(accessToken, refreshToken, expirationDate);
          }

          window.location.href = clientURL;
        });
      }
    }
  }, [clientURL, serverURL]);

  const storeTokenData = async (token, refreshToken, expirationDate) => {
    sessionStorage.setItem("accessToken", token);
    sessionStorage.setItem("refreshToken", refreshToken);
    sessionStorage.setItem("expirationDate", expirationDate);
  };

  const signOut = () => {
    sessionStorage.clear();
    window.location.href = serverURL;
  };

  const openHealthAutoExport = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const refreshToken = sessionStorage.getItem("refreshToken");
    const expDate = sessionStorage.getItem("expirationDate");

    const dateString = new Date(expDate);

    const haeURL = "com.HealthExport://google";

    const url = `${haeURL}?accessToken=${accessToken}&refreshToken=${refreshToken}&expirationDate=${dateString}`;
    window.location.href = url;
  };

  useEffect(() => {
    handleTokenFromQueryParams();
  }, [handleTokenFromQueryParams]);

  function TopBar() {
    return (
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "black",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff", // Choose a background color
                borderRadius: "50%", // Makes it fully circular
                padding: "8px", // Adds some space around the image
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/hae.png`}
                className="App-logo"
                alt="Health Auto Export logo"
                style={{
                  height: "40px", // Adjust size as needed
                  width: "40px", // Ensure width and height are the same for a circle
                  objectFit: "contain", // This ensures the image fits within the circle without stretching
                }}
              />
            </Box>
            <Typography
              variant={isMobile ? "h4" : "h2"}
              sx={{
                color: "#E6365E",
                fontWeight: "bold",
                paddingY: "40px",
                fontSize: {
                  xs: "1.5rem", // for extra-small devices
                  sm: "2rem", // for small devices
                  md: "2.5rem", // for medium devices
                  lg: "3rem", // for large devices
                },
              }}
            >
              Health Auto Export
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }

  function Description() {
    return (
      <Container maxWidth="sm">
        <Box sx={{ textAlign: "left", mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Connect to Google Drive
          </Typography>
          <Typography variant="body1" paragraph>
            Allow Health Auto Export to access your Google account in order to
            sync data directly to your Google Drive. Health Auto Export only
            uses this login process to get the required access tokens to upload
            data to your Google Drive.
          </Typography>
          <Box sx={{ textAlign: "left", mt: 4 }}>
            <Typography variant="h4" gutterBottom>
              Privacy
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="• does not collect any personally identifiable information about you (e.g., name, email address, location, etc)." />
              </ListItem>
              <ListItem>
                <ListItemText primary="• does not read the contents of your Google Drive except folders and files the app creates itself." />
              </ListItem>
              <ListItem>
                <ListItemText primary="• uploads files directly to your Google Drive over HTTPS." />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Container>
    );
  }

  function BottomActions() {
    return (
      <Container sx={{ paddingBottom: "30px" }}>
        {tokenExpired() ? (
          <div className="Drive-connect">
            <Button
              variant="contained"
              sx={{ fontWeight: "600" }}
              onClick={createGoogleAuthLink}
            >
              Connect Google Drive
            </Button>
          </div>
        ) : (
          <>
            <button className="Btn Action-btn" onClick={openHealthAutoExport}>
              Open Health Auto Export
            </button>
            <button className="Btn Neutral-btn" onClick={signOut}>
              Cancel
            </button>
          </>
        )}
      </Container>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <TopBar />
      <Container
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: (theme) =>
            `calc(${theme.spacing(isMobile ? 10 : 15)} + ${
              theme.mixins.toolbar.minHeight
            }px)`,
          // Adjust the padding-top value as needed
          textAlign: "center",
        }}
      >
        <Description />
        <BottomActions />
      </Container>
    </Box>
  );
}

export default App;
