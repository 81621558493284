const getEnv = (url) => {
  switch (true) {
    case url.includes("localhost"):
      return "dev";
    case url.includes("test.healthyapps.dev"):
      return "test";
    case url.includes("api.healthyapps.dev"):
      return "prod";
    default:
      throw new Error("Unhandled environment.");
  }
};

const serverURL = (env) => {
  switch (env) {
    case "dev":
      return "http://localhost:3001";
    case "test":
      return "https://test.healthyapps.dev";
    case "prod":
      return "https://api.healthyapps.dev";
    default:
      throw new Error("Unhandled server environment.");
  }
};

const clientURL = (env) => {
  switch (env) {
    case "dev":
      return "http://localhost:3000";
    case "test":
      return "https://test.healthyapps.dev";
    case "prod":
      return "https://api.healthyapps.dev";
    default:
      throw new Error("Unhandled client environment.");
  }
};

const urlConfig = { getEnv, serverURL, clientURL };
export default urlConfig;
